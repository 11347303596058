import { deployedEnvironment } from 'utils/env-utils'

const currentEnvironment = deployedEnvironment();
const filterByEnvironment = currentEnvironment !== 'development';
const environmentFilter = filterByEnvironment ? `&& '${currentEnvironment}' in launchedEnvironments` : '';

const getBlockContent = fieldName => `${fieldName}[]{
  ...,
  markDefs[]{
    ...,
    _type == "internalLink" => {
      "slug": @.reference->slug
    }
  },
}`

export const homeContentQuery = `*[_type=="homeContent"][0] | {
  title,
  heroCopy,
  why[],
  what[]{title, content, examples, "img": img.asset -> url, "url": link.reference -> slug.current, "linkText": link.linkText},
  clients[]{company, url, "img": img.asset -> url},
  partners[]{company, url, "img": img.asset -> url},
  testimonials[active == true]{name, title, content, "img": img.asset -> url}
}`

export const aboutContentQuery = `*[_type=="aboutContent"][0] | { 
  description,
  members[]{name, title, "img": img.asset -> url, bio},
}`

export const caseStudyQuery = `*[_type=="caseStudy"] | {
  client,
  project,
  "slug": slug.current,
  "mainImage": mainImage.asset -> url,
  description,
  timeframe,
  challenge,
  approach,
  outcome,
  "deepDive": ${getBlockContent("deepDive")},
  "clientLogo": clientLogo.asset -> url,
  ctaPrompt,
  // technologies[]->{name, url, "image": image.asset -> url}
}`

export const onePagerQuery = `*[_type=="onePager"] | {
  client,
  "clientLogo": clientLogo.asset -> url,
  clientDescription,
  title,
  "slug": slug.current,
  "mainImage": mainImage.asset -> url,
  description,
  challenge,
  solution,
  results,
  technologies
}`

export const solutionQuery = `*[_type=="solutionPage" ${environmentFilter}] | {
  title,
  documentTitle,
  tagline,
  "mainImage": mainImage.asset -> url,
  "bannerImage": bannerImage.asset -> url,
  "slug": slug.current,
  "body": ${getBlockContent("body")},
  testimonials[active == true]{name, title, content, "img": img.asset -> url},
}`

export const indexSolutionQuery = `*[_type=="solutionPage" && visibleOnIndex ${environmentFilter}] | {
  title,
  documentTitle,
  tagline,
  "mainImage": mainImage.asset -> url,
  "bannerImage": bannerImage.asset -> url,
  "slug": slug.current,
  "body": ${getBlockContent("body")},
}`

export const blogQuery = `*[_type=="blogPost" ${environmentFilter}] | {
  title,
  "slug": slug.current,
  author,
  "mainImage": mainImage.asset -> url,
  "content": ${getBlockContent("content")},
  ctaPrompt,
}`

export const indexBlogQuery = `*[_type=="blogPost" && visibleOnIndex ${environmentFilter}] | {
  title,
  "slug": slug.current,
  author,
  "mainImage": mainImage.asset -> url,
  "content": ${getBlockContent("content")},
  ctaPrompt,
}`

export const customerQuery = `*[_type=="customer"] | {
  name,
  image,
  url
}`

export const subprocessorQuery = `*[_type=="subprocessor"] | {
  name,
  activities,
  location,
  dataTransferMeans,
  securityDocumentationLinks,
  _updatedAt
}`