import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom/client';

// DOM Packages
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ScrollToTop } from './utils/ScrollToTop'


// Contexts
import { ContentProvider } from 'contexts/ContentContext';

// Style
import 'style/index.scss'

import * as serviceWorker from './serviceWorker';

// Google Tag Manager setup
import TagManager from 'react-gtm-module'

// Components
const Header = lazy(async () => (await import('components/Header')));
const Footer = lazy(async () => (await import('components/Footer')));

// Pages
const Home = lazy(async () => (await import('pages/Home')));
const About = lazy(async () => (await import('pages/About')));
const CaseStudy = lazy(async () => (await import('pages/CaseStudy')));
const OnePager = lazy(async () => (await import('pages/OnePager')));
const Solutions = lazy(async () => (await import('pages/Solutions')));
const Blog = lazy(async () => (await import('pages/Blog')));
const BlogPost = lazy(async () => (await import('pages/BlogPost')));
const SolutionV2 = lazy(async () => (await import('pages/SolutionV2')))
const Terms = lazy(async() => (await import('pages/Terms')));
const Privacy = lazy(async() => (await import('pages/Privacy')));
const Subprocessors = lazy(async() => (await import('pages/Subprocessors')));

const tagManagerArgs = {
  gtmId: 'GTM-P3SSNLQ'
}

TagManager.initialize(tagManagerArgs)

function App() {
  return(
    <ContentProvider>
        <Router>
          <ScrollToTop />
            <Suspense fallback={    
              <div className="full-page-loader">
                <svg width="30" height="40" viewBox="0 0 30 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M22.6115 10.0315C23.3769 11.3562 25.467 11.5623 27.2922 10.5025C29.1174 9.44269 29.9712 7.52918 29.2057 6.20444C28.4403 4.8797 26.3502 4.67363 24.525 5.73342C22.7292 6.79322 21.8461 8.70673 22.6115 10.0315Z" fill="#1654F5"/>
                  <path d="M19.697 3.5255C18.6078 4.6736 18.3723 6.23385 19.1671 7.02869C19.9914 7.7941 21.5222 7.49971 22.6115 6.32217C23.7007 5.17406 23.9362 3.61381 23.1414 2.81897C22.3465 2.083 20.8157 2.37739 19.697 3.5255Z" fill="#1654F5"/>
                  <path d="M15.3107 2.31861C14.5158 3.40784 14.4864 4.76202 15.2518 5.32135C16.0172 5.88069 17.2831 5.43911 18.0779 4.34988C18.8728 3.26065 18.9022 1.90647 18.1368 1.34714C17.3714 0.787802 16.1055 1.22938 15.3107 2.31861Z" fill="#1654F5"/>
                  <path d="M13.884 3.52151C14.499 2.41251 14.3868 1.17468 13.6332 0.756755C12.8797 0.338829 11.7702 0.899064 11.1551 2.00807C10.54 3.11708 10.6523 4.35491 11.4059 4.77283C12.1594 5.19076 13.2689 4.63052 13.884 3.52151Z" fill="#1654F5"/>
                  <path d="M9.6879 3.76104C9.36408 4.67364 8.59867 5.26242 7.98046 5.05634C7.33281 4.85027 7.06786 3.90824 7.39169 2.99564C7.71551 2.08304 8.48092 1.49427 9.09913 1.70034C9.71734 1.90641 9.98229 2.84845 9.6879 3.76104Z" fill="#1654F5"/>
                  <path d="M15.3695 19.9818C19.1083 18.0094 24.2895 16.4786 22.1404 11.7684C20.5213 8.26522 15.3695 6.17508 11.5425 6.17508C8.03932 6.1162 5.12489 7.79421 3.41745 11.4152C1.82776 14.7712 0.885725 18.922 0.238075 22.7785C-0.350698 26.4877 0.179198 31.6689 1.53338 35.0544C2.74036 38.0277 6.00805 40.7361 9.54069 39.323C10.8654 38.7048 11.9547 37.645 12.1313 37.4095C15.3695 33.4058 12.6317 29.0195 11.3364 25.163C10.5122 22.6018 13.1911 21.1299 15.3695 19.9818ZM7.80381 19.9524C6.33188 22.4841 6.24356 25.5751 7.68605 28.2835C9.48181 30.9919 10.1 32.2577 9.74676 33.7591C9.51125 34.76 8.21595 35.8787 7.39167 35.1721C5.94917 34.0829 5.30152 31.7278 4.94826 30.1087C3.82959 24.6037 5.21321 20.6589 5.77254 17.8917C6.36131 15.0361 7.09728 12.2983 9.68788 11.003C12.3668 9.64884 16.3116 10.4142 17.4597 11.9156C18.6372 13.7997 17.4008 14.8595 15.0163 15.4188C12.3374 16.0665 8.95192 17.9505 7.80381 19.9524Z" fill="#1654F5"/>
                </svg>
              </div>
            }>
              <Header />
              <Switch>
                <Route exact path="/about" component={ About }/>
                <Route exact path="/one-pager/:slug" component={ OnePager }/>
                <Route exact path="/case-study/:slug" component={ CaseStudy }/>
                <Route exact path="/solutions" component={ Solutions } />
                <Route exact path="/solution/:slug" component={ SolutionV2 }/>
                <Route exact path="/blog" component={ Blog } />
                <Route exact path="/blog/:slug" component={ BlogPost }/>
                <Route exact path="/terms" component={Terms} />
                <Route exact path="/privacy" component={Privacy} />
                <Route exact path="/subprocessors" component={Subprocessors} />
                <Route path="/" component={ Home }/>
              </Switch>
              <Footer />
            </Suspense>
        </Router>
    </ContentProvider>
  )
}

const container = document.getElementById('root');

// Create a root.
const root = ReactDOM.createRoot(container);

// Initial render
window.onload = () => {
  root.render(<App />);
}

serviceWorker.register();
