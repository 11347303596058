export const isTrue = (environmentVariable) => {
  return environmentVariable === 'true'
}

export function inDevelopment () {
  return isTrue(process.env.REACT_APP_DEVELOPMENT)
}

export function deployedEnvironment() {
  if (isTrue(process.env.REACT_APP_IS_PRODUCTION)) {
    return 'production';
  } else if (isTrue(process.env.REACT_APP_IS_STAGING)) {
    return 'staging';
  } else if (inDevelopment()) {
    return 'development'
  } else {
    console.log("Tried to determine SDLC environment, but couldn't. Assuming development.")
    return 'development';
  }
}

export const createUserId = () => {
  return (
    `${Math.random()
      .toString(36)
      .slice(2)}_` +
    `${Math.random()
      .toString(36)
      .slice(2)}`
  )
}

export const getAppContainerID = () => {
  return process.env.REACT_APP_CONTAINER_ID || "root"
}