import React, { useState, useEffect } from 'react';
import { getData } from 'utils/sanity/'

// Queries
import { homeContentQuery, aboutContentQuery, caseStudyQuery, solutionQuery, indexSolutionQuery, blogQuery, indexBlogQuery, onePagerQuery, customerQuery, subprocessorQuery } from "utils/sanity/sanity-queries"

const ContentContext = React.createContext({});

const ContentProvider = (props) => {
  // Set two loading states for without and with data
  const [loaded, setLoaded] = useState(false);
  // Set a project state
  const [content, setContent] = useState([]);
  const [aboutContent, setAboutContent] = useState([]);
  const [caseStudies, setCaseStudies] = useState([]);
  const [solutions, setSolutions] = useState([]);
  const [indexSolutions, setIndexSolutions] = useState([]);
  const [blogPosts, setBlogPosts] = useState([]);
  const [indexBlogPosts, setIndexBlogPosts] = useState([]);
  const [onePagers, setOnePagers] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [subprocessors, setSubprocessors] = useState([]);


  // Get the data from Sanity
  useEffect(() => {
    async function fetchData() { 
      try {
        // Get Home Content
        const homeContentResponse = await getData(homeContentQuery)
        setContent(homeContentResponse)
        // Get About Content
        const aboutContentResponse = await getData(aboutContentQuery)
        setAboutContent(aboutContentResponse)
        // Get Case Studies
        const caseStudyResponse = await getData(caseStudyQuery)
        setCaseStudies(caseStudyResponse)

        const solutionsResponse = await getData(solutionQuery)
        setSolutions(solutionsResponse)

        const indexSolutionsResponse = await getData(indexSolutionQuery);
        setIndexSolutions(indexSolutionsResponse);

        const blogResponse = await getData(blogQuery)
        setBlogPosts(blogResponse)
        
        const indexBlogResponse = await getData(indexBlogQuery)
        setIndexBlogPosts(indexBlogResponse)

        const onePagerResponse = await getData(onePagerQuery)
        setOnePagers(onePagerResponse)
        
        const customerResponse = await getData(customerQuery)
        setCustomers(customerResponse)

        const subprocessorResponse = await getData(subprocessorQuery)
        setSubprocessors(subprocessorResponse)

        setLoaded(true)
      }
      catch (err) {
        console.log('fetch failed', err);
        throw err
      }
    }
    fetchData()
  }, []);

  return (
    <ContentContext.Provider value={{ loaded, content, aboutContent, caseStudies, solutions, indexSolutions, blogPosts, indexBlogPosts, onePagers, customers, subprocessors }}>
      {props.children}
    </ContentContext.Provider>
  );
}

export { ContentContext, ContentProvider };